import React from 'react';
import AppProvider from '../components/providers/AppProvider';
import PageSettingsWrapper from '../components/PageSettingsWrapper';


const PageWrapper = ({children}) => {
    return (
        <>
            <AppProvider>
                <PageSettingsWrapper>
                    {children}
                </PageSettingsWrapper>
            </AppProvider>
        </>
    )
}

export default PageWrapper;
import React from 'react';
import PageWrapper from './src/components/PageWrapper'


export const wrapPageElement = ({element, props}) => (
    <PageWrapper {...props}>{element}</PageWrapper>
);

// export const onInitialClientRender = () => {
//     console.log(localStorage.getItem('state'));
//     try {
//         const serializedState = localStorage.getItem('state');
//         if (serializedState === null) {
//           return false;
//         }
//         const settings = JSON.parse(serializedState);
//         if (settings && settings.darkMode) {
//             document.body.classList.add('mode-dark');
//         }

//         if (settings && settings.theme) {
//             document.body.classList.add(settings.theme);
//         }
//     } catch (err) {

//     }
// }
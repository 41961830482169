import React, {useState, useEffect, createContext} from 'react';


export const AppContext = createContext();

const defaultData = {
    darkMode: false,
    theme: 'theme-default'
};

const getInitialData = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
          return defaultData;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return defaultData;
    }
}


const AppProvider = ({children}) => {
    const [settings, setSettings] = useState(getInitialData);

    const toggleDarkMode = () => {
        setSettings({...settings, darkMode: !settings.darkMode});
    };

    const setTheme = (themeName) => {
        setSettings({...settings, theme: themeName});
    }

    useEffect(() => {
        localStorage.setItem('state', JSON.stringify(settings));
    }, [settings]);

    return (
        <AppContext.Provider value={{settings, toggleDarkMode, setTheme}}>
            {children}
        </AppContext.Provider>
    );
}

export default AppProvider;
import React, {useContext, useEffect} from 'react';
import {AppContext} from '../components/providers/AppProvider';
import {themes} from '../common/constants';

const PageSettingsWrapper = ({children}) => {
    const  {settings} = useContext(AppContext);

    useEffect(() => {
        if (settings.darkMode) {
            document.body.classList.add('mode-dark');
        } else {
            document.body.classList.remove('mode-dark');
        }
    }, [settings.darkMode]);


    useEffect(() => {
       themes.forEach(theme => {
        document.body.classList.remove(theme.name);
       });
       document.body.classList.add(settings.theme);
    }, [settings.theme]);

    return (
        <>
            {children}
        </>
    );
}

export default PageSettingsWrapper;